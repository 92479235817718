import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCode, faDesktop, faChalkboardTeacher, faHandsHelping, faVideo, faNetworkWired, faLaptopCode, faPhone } from "@fortawesome/free-solid-svg-icons";
import img_consultoria from "../srv_img_consultoria.png";
import img_desarrollo from "../srv_img_desweb.jpeg";
import img_pagweb from "../srv_img_pagweb.jpeg";
import img_cableado from "../srv_img_cableado.jpeg";
import img_cctv from "../srv_img_cctv.png";
import img_workstation from "../srv_img_workstation.jpeg";
import img_help from "../srv_img_help.jpeg";
import img_capacitacion from "../srv_img_capacitacion.jpeg";
const Services = () =>{

   

    const [sw, setSw] = useState(false);
    const [srvindex, setSrvindex] = useState(0);
    return (
        <div id="services" className="services">
            <br/>
            <h1 className="py-5">Servicios</h1>
                <div className="container">
                    <div hidden={sw} className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6" onClick={() => {setSw(true); setSrvindex(1)}}>
                            <div className="box">
                                <div className="circle">
                                    <FontAwesomeIcon className="icon" icon={faHandsHelping} size="2x"/>
                                </div>
                                <h3>Consultoría</h3>
                                <p>Identificamos las áreas de oportunidad de mejora en las empresas a través de sistemas informáticos.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6" onClick={() => {setSw(true); setSrvindex(2)}}>
                            <div className="box">
                            <div className="circle">
                                    <FontAwesomeIcon className="icon" icon={faLaptopCode} size="2x"/>
                                </div>
                                <h3>Desarrollos Web</h3>
                                <p>Desarrollamos sistemas web a la medida de sus necesidades, accede a ellos a través de internet desde cualquier lugar.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6"  onClick={() => {setSw(true); setSrvindex(3)}}>
                            <div className="box">
                            <div className="circle">
                                    <FontAwesomeIcon className="icon" icon={faFileCode} size="2x"/>
                                </div>
                                <h3>Sitios Web</h3>
                                <p>Maximize la difusión de su negocio, creamos para ti Sitios Web y Landing pages responsivos que generan impacto.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6" onClick={() => {setSw(true); setSrvindex(4)}}>
                            <div className="box">
                            <div className="circle">
                                    <FontAwesomeIcon className="icon" icon={faNetworkWired} size="2x"/>
                                </div>
                                <h3>Cableado Estructurado</h3>
                                <p>Realizamos instalación y configuración de su red y VPN's.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row" hidden={sw}>
                        <div className="col-lg-3 col-md-6 col-sm-6" onClick={() => {setSw(true); setSrvindex(5)}}>
                            <div className="box">
                                <div className="circle">
                                    <FontAwesomeIcon className="icon" icon={faVideo} size="2x"/>
                                </div>
                                <h3>Instalación CCTV</h3>
                                <p>Mantenga monitoreada las áreas de su empresa a través de cámaras de alta definición.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6" onClick={() => {setSw(true); setSrvindex(6)}}>
                            <div className="box">
                            <div className="circle">
                                    <FontAwesomeIcon className="icon" icon={faDesktop} size="2x"/>
                                </div>
                                <h3>Venta de Equipo</h3>
                                <p>Contamos con diversas opciones de equipos de computo según sea su necesidad.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6" onClick={() => {setSw(true); setSrvindex(7)}}>
                            <div className="box">
                            <div className="circle">
                                    <FontAwesomeIcon className="icon" icon={faPhone} size="2x"/>
                                </div>
                                <h3>Mesa de Servicio</h3>
                                <p>Implementamos su mesa de servicio con ayuda de un software de control.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6" onClick={() => {setSw(true); setSrvindex(8)}}>
                            <div className="box">
                            <div className="circle">
                                    <FontAwesomeIcon className="icon" icon={faChalkboardTeacher} size="2x"/>
                                </div>
                                <h3>Capacitación</h3>
                                <p>Capacitamos al personal de tu empresa en el uso de Paquetería Office (Excel, Word, PowerPoint).</p>
                            </div>
                        </div>
                    </div>
                    {
                        
                    sw===true && srvindex===1?
                    <div className="container py-5" style={{color:'white'}}>
                        
                        <div className="row">
                        <div className="col-lg-6 col-xm-12">
                        
                            <div className="photo-wrap mb-5">
                            <img className="service-img" src={img_consultoria} alt="foto..."/>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xm-12">
                                <h2 style={{textAlign:'left',color:'#f9ab00'}}> Consultoría</h2>
                                <p style={{textAlign:"justify"}}>
                                Nuestros servicios de consultoría están destinados a mejorar tu eficiencia y
                                productividad mediante soluciones tecnológicas adaptadas a tus necesidades. 
                                Nuestra especialización sectorial nos permite aportar soluciones totalmente
                                 confiables a los complejos retos que los mercados presentan a las empresas.
                                <br/><br/>
                                Nos basamos en el uso de Tecnologías de la Información que tienen como principal
                                objetivo colaborar y dar soporte a los procesos de negocio de las empresas y/o clientes.
                                Los Servicios IT se componen de una combinación de personas, procesos y tecnología.

                                </p>
                            </div>
                            
                        </div>
                        <br/><br/>
                        <button className="btn_service" onClick={() => setSw(false)}> Otros servicios</button>
                    </div>:
                    sw===true && srvindex===2?
                    <div className="container py-5" style={{color:'white'}}>
                        
                        <div className="row">
                        <div className="col-lg-6 col-xm-12">
                        
                            <div className="photo-wrap mb-5">
                            <img className="service-img" src={img_desarrollo} alt="foto..."/>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xm-12">
                                <h2 style={{textAlign:'left',color:'#f9ab00'}}> Desarrollo Web</h2>
                                <p style={{textAlign:"justify"}}>
                                Realizamos la personalización total del diseño y las funcionalidades
                                que tu negocio necesita. Partiendo de cero,
                                podemos obtener un diseño único según los intereses y objetivos que establezcan.
                                <br/><br/>
                                Contamos con las mas modernas herramientas de desarrollo y experimentados programadores, lo cual nos permite realizar
                                su sistema web en poco tiempo.
                                <br/><br/>
                                Utilizamos metodologías ágil en el desarrollo del proyecto de tu sistema web, por lo que siempre
                                conoceras los avances del desarrollo asegurandonos que cada módulo quede a su total satisfacción.

                                </p>
                            </div>
                            
                        </div>
                        <br/><br/>
                        <button className="btn_service" onClick={() => setSw(false)}> Otros servicios</button>
                    </div>:
                    sw===true && srvindex===3?
                    <div className="container py-5" style={{color:'white'}}>
                        
                        <div className="row">
                        <div className="col-lg-6 col-xm-12">
                        
                            <div className="photo-wrap mb-5">
                            <img className="service-img" src={img_pagweb} alt="foto..."/>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xm-12">
                                <h2 style={{textAlign:'left',color:'#f9ab00'}}> Sitios Web</h2>
                                <p style={{textAlign:"justify"}}>
                                Tu empresa necesita darse a conocer y tener una fuerte presencia
                                en Internet desde que inicia en el mundo digital; es por esto,
                                que podemos ayudarte a generar el impacto que necesitas para conseguir
                                nuevos clientes para tu negocio. 
                                <br/><br/>
                                En un sitio web podrás compartir información acerca de tus productos y
                                servicios, contacto, tu historia, fotos y vídeos que quieras mostrar
                                a tus visitantes. Sin importar el tamaño de tu empresa en Pisys tienes 
                                a un gran aliado para adentrar al mundo digital creando tu nuevo y exitoso sitio web.

                                </p>
                            </div>
                            
                        </div>
                        <br/><br/>
                        <button className="btn_service" onClick={() => setSw(false)}> Otros servicios</button>
                    </div>:
                    sw===true && srvindex===4?
                    <div className="container py-5" style={{color:'white'}}>
                        
                        <div className="row">
                        <div className="col-lg-6 col-xm-12">
                        
                            <div className="photo-wrap mb-5">
                            <img className="service-img" src={img_cableado} alt="foto..."/>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xm-12">
                                <h2 style={{textAlign:'left',color:'#f9ab00'}}> Cableado Estructurado</h2>
                                <p style={{textAlign:"justify"}}>
                                Pisys Corporation es una empresa líder portadora de soluciones avanzadas de 
                                tecnologías de información administrativas de clase mundial con un alto
                                enfoque de servicio al cliente, innovación, valor agregado y consultoría
                                especializada.
                                <br/><br/>
                                Nuestro compromiso con los clientes y valores organizacionales nos permiten
                                ofrecer seriedad, confidencialidad, honestidad, propuestas justas con
                                alternativas de soluciones reales con calidad y profesionalismo, otorgándole al
                                cliente la posibilidad de optimizar sus recursos, reducir costos, estructurar
                                procesos internos para la toma de decisiones, ser más eficiente, metódico y 
                                más competitivo.

                                </p>
                            </div>
                            
                        </div>
                        <br/><br/>
                        <button className="btn_service" onClick={() => setSw(false)}> Otros servicios</button>
                    </div>:
                    sw===true && srvindex===5?
                    <div className="container py-5" style={{color:'white'}}>
                        
                        <div className="row">
                        <div className="col-lg-6 col-xm-12">
                        
                            <div className="photo-wrap mb-5">
                            <img className="service-img" src={img_cctv} alt="foto..."/>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xm-12">
                                <h2 style={{textAlign:'left',color:'#f9ab00'}}> Instalación CCTV</h2>
                                <p style={{textAlign:"justify"}}>
                                Pisys Corporation es una empresa líder portadora de soluciones avanzadas de 
                                tecnologías de información administrativas de clase mundial con un alto
                                enfoque de servicio al cliente, innovación, valor agregado y consultoría
                                especializada.
                                <br/><br/>
                                Nuestro compromiso con los clientes y valores organizacionales nos permiten
                                ofrecer seriedad, confidencialidad, honestidad, propuestas justas con
                                alternativas de soluciones reales con calidad y profesionalismo, otorgándole al
                                cliente la posibilidad de optimizar sus recursos, reducir costos, estructurar
                                procesos internos para la toma de decisiones, ser más eficiente, metódico y 
                                más competitivo.

                                </p>
                            </div>
                            
                        </div>
                        <br/><br/>
                        <button className="btn_service" onClick={() => setSw(false)}> Otros servicios</button>
                    </div>:
                    sw===true && srvindex===6?
                    <div className="container py-5" style={{color:'white'}}>
                        
                        <div className="row">
                        <div className="col-lg-6 col-xm-12">
                        
                            <div className="photo-wrap mb-5">
                            <img className="service-img" src={img_workstation} alt="foto..."/>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xm-12">
                                <h2 style={{textAlign:'left',color:'#f9ab00'}}> Venta de Equipo de Computo</h2>
                                <p style={{textAlign:"justify"}}>
                                Pisys Corporation es una empresa líder portadora de soluciones avanzadas de 
                                tecnologías de información administrativas de clase mundial con un alto
                                enfoque de servicio al cliente, innovación, valor agregado y consultoría
                                especializada.
                                <br/><br/>
                                Nuestro compromiso con los clientes y valores organizacionales nos permiten
                                ofrecer seriedad, confidencialidad, honestidad, propuestas justas con
                                alternativas de soluciones reales con calidad y profesionalismo, otorgándole al
                                cliente la posibilidad de optimizar sus recursos, reducir costos, estructurar
                                procesos internos para la toma de decisiones, ser más eficiente, metódico y 
                                más competitivo.

                                </p>
                            </div>
                            
                        </div>
                        <br/><br/>
                        <button className="btn_service" onClick={() => setSw(false)}> Otros servicios</button>
                    </div>:
                    sw===true && srvindex===7?
                    <div className="container py-5" style={{color:'white'}}>
                        
                        <div className="row">
                        <div className="col-lg-6 col-xm-12">
                        
                            <div className="photo-wrap mb-5">
                            <img className="service-img" src={img_help} alt="foto..."/>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xm-12">
                                <h2 style={{textAlign:'left',color:'#f9ab00'}}> Mesa de Servicio</h2>
                                <p style={{textAlign:"justify"}}>
                                Pisys Corporation es una empresa líder portadora de soluciones avanzadas de 
                                tecnologías de información administrativas de clase mundial con un alto
                                enfoque de servicio al cliente, innovación, valor agregado y consultoría
                                especializada.
                                <br/><br/>
                                Nuestro compromiso con los clientes y valores organizacionales nos permiten
                                ofrecer seriedad, confidencialidad, honestidad, propuestas justas con
                                alternativas de soluciones reales con calidad y profesionalismo, otorgándole al
                                cliente la posibilidad de optimizar sus recursos, reducir costos, estructurar
                                procesos internos para la toma de decisiones, ser más eficiente, metódico y 
                                más competitivo.

                                </p>
                            </div>
                            
                        </div>
                        <br/><br/>
                        <button className="btn_service" onClick={() => setSw(false)}> Otros servicios</button>
                    </div>:
                    sw===true && srvindex===8?
                    <div className="container py-5" style={{color:'white'}}>
                        
                        <div className="row">
                        <div className="col-lg-6 col-xm-12">
                        
                            <div className="photo-wrap mb-5">
                            <img className="service-img" src={img_capacitacion} alt="foto..."/>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xm-12">
                                <h2 style={{textAlign:'left',color:'#f9ab00'}}> Capacitación</h2>
                                <p style={{textAlign:"justify"}}>
                                Pisys Corporation es una empresa líder portadora de soluciones avanzadas de 
                                tecnologías de información administrativas de clase mundial con un alto
                                enfoque de servicio al cliente, innovación, valor agregado y consultoría
                                especializada.
                                <br/><br/>
                                Nuestro compromiso con los clientes y valores organizacionales nos permiten
                                ofrecer seriedad, confidencialidad, honestidad, propuestas justas con
                                alternativas de soluciones reales con calidad y profesionalismo, otorgándole al
                                cliente la posibilidad de optimizar sus recursos, reducir costos, estructurar
                                procesos internos para la toma de decisiones, ser más eficiente, metódico y 
                                más competitivo.

                                </p>
                            </div>
                            
                        </div>
                        <br/><br/>
                        <button className="btn_service" onClick={() => setSw(false)}> Otros servicios</button>
                    </div>:''
                    }
                </div>
                <br/>
            </div>
    )
}

export default Services;