import React from "react";
import {PopupboxManager, PopupboxContainer} from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";

import img_pos from "../soft_img_pos.png";
import img_inmobiliaria from "../soft_img_inmobiliaria.png";
import Portfolio3 from "../portfolio3.png";
import Portfolio4 from "../portfolio4.jpg";





////// Portfolio 1
const Software = () =>{

    const openPopupboxPortfolio1 = () =>{
        const content = (
        <>
            <img className="portfolio-image-popupbox" src={img_pos} alt="Pisys POS..."/>
            <p style={{textAlign:"justify"}}>Software punto de venta, para ser utilizado por diferentes tipos de negocios, especializado para venta de productos y renta de activos.</p>
            <b>Demo:</b> <a className="hyper-link" onClick={()=> window.open("https://github.com/8020Coding/netflix-project")}>https://github.com/8020Coding/netflix-project</a>
            
        </>
        )
        PopupboxManager.open({content,config: {
            titleBar: {
                enable:true,
              text: 'Pisys POS (Punto de Venta).'
            },
            fadeIn: true,
            fadeInSpeed: 500
        }})
    }

    
    ////// Portfolio 2

    const openPopupboxPortfolio2 = () =>{
        const content = (
        <>
            <img className="portfolio-image-popupbox" src={img_inmobiliaria} alt="Pisys Inmobiliaria..."/>
            <p style={{textAlign:"justify"}}>Portafolio2, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <b>Demo:</b> <a className="hyper-link" onClick={()=> window.open("https://github.com/8020Coding/netflix-project")}>https://github.com/8020Coding/netflix-project</a>
            
        </>
        )
        PopupboxManager.open({content,config: {
            titleBar: {
                enable:true,
              text: 'Pisys Inmobiliaria'
            },
            fadeIn: true,
            fadeInSpeed: 500
        }})
    }

    

    ////// Portfolio 3

    const openPopupboxPortfolio3 = () =>{
        const content = (
        <>
            <img className="portfolio-image-popupbox" src={Portfolio3} alt="Portfolio3..."/>
            <p style={{textAlign:"justify"}}>Portafolio3, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <b>Demo:</b> <a className="hyper-link" onClick={()=> window.open("https://github.com/8020Coding/netflix-project")}>https://github.com/8020Coding/netflix-project</a>
            
        </>
        )
        PopupboxManager.open({content,config: {
            titleBar: {
                enable:true,
              text: 'Portfolio3'
            },
            fadeIn: true,
            fadeInSpeed: 500
        }})
    }

   

    ////// Portfolio 4

    const openPopupboxPortfolio4 = () =>{
        const content = (
        <>
            <img className="portfolio-image-popupbox" src={Portfolio4} alt="Portfolio4..."/>
            <p style={{textAlign:"justify"}}>Portafolio4, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <b>Demo:</b> <a className="hyper-link" onClick={()=> window.open("https://github.com/8020Coding/netflix-project")}>https://github.com/8020Coding/netflix-project</a>
            
        </>
        )
        PopupboxManager.open({content,config: {
            titleBar: {
                enable:true,
              text: 'Portfolio4'
            },
            fadeIn: true,
            fadeInSpeed: 500
          }})
    }

    


    return (
        <div id="software" className="portfolio-wrapper">
            <br/>
            <div className="container">
                <h1 className="py-5">software</h1>
                <div className="image-box-wrapper row justify-content-center">
                    <div className="portfolio-image-box" onClick={openPopupboxPortfolio1}>
                        
                        <img className="portfolio-image" src={img_pos} alt="portfolio1..." />
                        <div className="overflow"><p style={{textAlign:'center',color:'white', paddingTop:50}}>Pisys POS</p></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                    <div className="portfolio-image-box" onClick={openPopupboxPortfolio2}>
                        <img className="portfolio-image" src={img_inmobiliaria} alt="portfolio2..." />
                        <div className="overflow"><p style={{textAlign:'center',color:'white', paddingTop:50}}>Pisys Inmobiliaria</p></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                    <div className="portfolio-image-box" onClick={openPopupboxPortfolio3}>
                        <img className="portfolio-image" src={Portfolio3} alt="portfolio3..." />
                        <div className="overflow"></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                    <div className="portfolio-image-box" onClick={openPopupboxPortfolio4}>
                        <img className="portfolio-image" src={Portfolio4} alt="portfolio4..." />
                        <div className="overflow"></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                </div>
            </div>
            <PopupboxContainer />
            <br/>
        </div>
    )
}

export default Software;