import React from "react";
import {PopupboxManager, PopupboxContainer} from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";

import img_imachine from "../eq_img_imachine.jpeg";
import Portfolio2 from "../portfolio2.jpg";
import Portfolio3 from "../portfolio3.png";
import Portfolio4 from "../portfolio4.jpg";





////// Portfolio 1
const Hardware = () =>{

    const openPopupboxPortfolio1 = () =>{
        const content = (
        <>
            <img className="portfolio-image-popupbox" src={img_imachine} alt="All in one"/>
            <p style={{textAlign:"justify"}}>Portafolio1, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <b>Demo:</b> <a className="hyper-link" onClick={()=> window.open("https://github.com/8020Coding/netflix-project")}>https://github.com/8020Coding/netflix-project</a>
            
        </>
        )
        PopupboxManager.open({content,config: {
            titleBar: {
                enable:true,
              text: 'POS All in One'
            },
            fadeIn: true,
            fadeInSpeed: 500
        }})
    }

    
    ////// Portfolio 2

    const openPopupboxPortfolio2 = () =>{
        const content = (
        <>
            <img className="portfolio-image-popupbox" src={Portfolio2} alt="Portfolio2..."/>
            <p style={{textAlign:"justify"}}>Portafolio2, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <b>Demo:</b> <a className="hyper-link" onClick={()=> window.open("https://github.com/8020Coding/netflix-project")}>https://github.com/8020Coding/netflix-project</a>
            
        </>
        )
        PopupboxManager.open({content,config: {
            titleBar: {
                enable:true,
              text: 'Portfolio2'
            },
            fadeIn: true,
            fadeInSpeed: 500
        }})
    }

    

    ////// Portfolio 3

    const openPopupboxPortfolio3 = () =>{
        const content = (
        <>
            <img className="portfolio-image-popupbox" src={Portfolio3} alt="Portfolio3..."/>
            <p style={{textAlign:"justify"}}>Portafolio3, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <b>Demo:</b> <a className="hyper-link" onClick={()=> window.open("https://github.com/8020Coding/netflix-project")}>https://github.com/8020Coding/netflix-project</a>
            
        </>
        )
        PopupboxManager.open({content,config: {
            titleBar: {
                enable:true,
              text: 'Portfolio3'
            },
            fadeIn: true,
            fadeInSpeed: 500
        }})
    }

   

    ////// Portfolio 4

    const openPopupboxPortfolio4 = () =>{
        const content = (
        <>
            <img className="portfolio-image-popupbox" src={Portfolio4} alt="Portfolio4..."/>
            <p style={{textAlign:"justify"}}>Portafolio4, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <b>Demo:</b> <a className="hyper-link" onClick={()=> window.open("https://github.com/8020Coding/netflix-project")}>https://github.com/8020Coding/netflix-project</a>
            
        </>
        )
        PopupboxManager.open({content,config: {
            titleBar: {
                enable:true,
              text: 'Portfolio4'
            },
            fadeIn: true,
            fadeInSpeed: 500
          }})
    }

    


    return (
        <div id="hardware" className="portfolio-wrapper2">
            <br/>
            <div className="container">
                <h1 className="py-5">hardware</h1>
                <div className="image-box-wrapper row justify-content-center">
                    <div className="portfolio-image-box" onClick={openPopupboxPortfolio1}>
                        
                        <img className="portfolio-image" src={img_imachine} alt="portfolio1..." />
                        <div className="overflow"><p style={{textAlign:'center',color:'white', paddingTop:50}}>POS (All in One)</p></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                    <div className="portfolio-image-box" onClick={openPopupboxPortfolio2}>
                        <img className="portfolio-image" src={Portfolio2} alt="portfolio2..." />
                        <div className="overflow"></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                    <div className="portfolio-image-box" onClick={openPopupboxPortfolio3}>
                        <img className="portfolio-image" src={Portfolio3} alt="portfolio3..." />
                        <div className="overflow"></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                    <div className="portfolio-image-box" onClick={openPopupboxPortfolio4}>
                        <img className="portfolio-image" src={Portfolio4} alt="portfolio4..." />
                        <div className="overflow"></div>
                        <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
                    </div>
                </div>
            </div>
            <PopupboxContainer />
            <br/>
        </div>
    )
}

export default Hardware;