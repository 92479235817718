
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Header from "./components/Header";
import About from "./components/About";
import Services from "./components/Services";
import Software from "./components/Software";
import Hardware from "./components/Hardware";
import Footer from "./components/Footer";


function App() {
  return (
    <>
      <Header />
      <About />
      <Services />
      <Software />
      <Hardware />
      <Footer />
    </>
  );

}

export default App;
