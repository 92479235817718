import React from "react";
import logo from "../logopisys.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-scroll";

const Navbar = () => {
   
    return (
    <nav className="navbar navbar-expand-lg">
        <div className="container" >
{/*             <Link smooth={true} to="home" className="navbar-brand" href="#"><img style={{width:200, paddingTop:15}} className="logo" src={logo} alt="logo..."/></Link>
 */}        <p style={{color:'white',fontSize:'1rem', paddingTop:10}}>Móvil/WhatsApp : </p>
            <p style={{margin:4}}></p>
            <p style={{color:'greenyellow',fontSize:'1rem', paddingTop:10}}>+1(52)5511295030</p>    
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <FontAwesomeIcon icon={faBars} style={{color:"#fff"}}/>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                        <Link smooth={true} to="home" offset={-100}  className="nav-link" href="#">Inicio <span class="sr-only">(current)</span></Link>
                    </li>
                    <li className="nav-item">
                        <Link smooth={true} to="about" offset={-100} className="nav-link" href="#">acerca de</Link>
                    </li>
                    <li className="nav-item">
                        <Link smooth={true} to="services" offset={-100} className="nav-link" href="#">servicios</Link>
                    </li>
                    <li className="nav-item">
                        <Link smooth={true} to="software" offset={-100} className="nav-link" href="#">software</Link>
                    </li>
                    <li className="nav-item">
                        <Link smooth={true} to="hardware" offset={-100} className="nav-link" href="#">hardware</Link>
                    </li>
                </ul>
            </div>
        </div>
</nav>
    )
}

export default Navbar