import React, { useState } from "react";
import acerca1 from "../acerca1.jpeg";
import acerca2 from "../acerca2.jpeg";
import acerca3 from "../acerca3.jpeg";
import acerca4 from "../acerca4.jpeg";
import adhome from "../logo_adHome.jpg";
import forzatel from "../logo_forzatel.png";
import fantasycap from "../fantasycap_logo.jpg";
import kiddocars from "../kiddocars_logo.jpg";
import cobalto from "../cobalto_logo.png";
import bkr from "../logo_bk3.png";

import {Link} from "react-scroll";

const About = () =>{

    const [vermas, setVermas] = useState(false);

    const govermas = () =>{
        setVermas(true);
    }

    const govermenos = () =>{
        setVermas(false);
    }

    return (
        <div id="about" style={{paddingTop:50}}>
           
            <section className="about-us">
                <div className="about-us-images"> 
                    <img className="profile-img profile-img1" src={acerca1} alt="foto..."/>
                    <img className="profile-img profile-img2" src={acerca2} alt="foto..."/>
                    <img className="profile-img profile-img3" src={acerca3} alt="foto..."/>
                    <img className="profile-img profile-img4" src={acerca4} alt="foto..."/>
                   
                </div>
                <div className="about-us-content">
                    <h1 className="about-heading"> Acerca de</h1>
                    <p style={{textAlign:"justify"}}>
                    Pisys Corporation es una empresa líder portadora de soluciones avanzadas de 
                    tecnologías de información administrativas de clase mundial con un alto
                    enfoque de servicio al cliente, innovación, valor agregado y consultoría
                    especializada.
                    <br/><br/>
                    Nuestro compromiso con los clientes y valores organizacionales nos permiten
                    ofrecer seriedad, confidencialidad, honestidad, propuestas justas con
                    alternativas de soluciones reales con calidad y profesionalismo, otorgándole al
                    cliente la posibilidad de optimizar sus recursos, reducir costos, estructurar
                    procesos internos para la toma de decisiones, ser más eficiente, metódico y 
                    más competitivo.

                    </p>
                </div>
            </section>
           
            <br/><br/><br/><br/><br/>
           
            <div >
                <div className="sectext">
                <h2> Quienes nos han favorecido con su preferencia.</h2>
                </div>
                <br/>
                <div className="container">
                    <div style={{textAlign:'center'}}>
                       
                    <img style={{width:'10rem'}} className="client-img" src={adhome} alt="foto..."/>
                   
                   
                    <img style={{width:'12rem'}} className="client-img" src={forzatel} alt="foto..."/>
                   
                   
                    <img style={{width:'7rem'}} className="client-img" src={fantasycap} alt="foto..."/>
                   
                    <img style={{width:'7rem',paddingLeft:20}} className="client-img" src={kiddocars} alt="foto..."/>
                   
                    <img style={{width:'8rem'}} className="client-img" src={cobalto} alt="foto..."/>
                   
                    <img style={{width:'11rem'}} className="client-img" src={bkr} alt="foto..."/>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    )
}

export default About