import React from "react";
import Typed from "react-typed";
import logo from "../logo_transparente.png";
import {Link} from "react-scroll";
import Navbar from "./Navbar";
const Header = () => {
    return(
        <div id="home" className="header-wraper">
            <Navbar />
           
            <div className="main-info">
                
            <p className="companyname"><img src={logo} alt="logo" style={{width:120, paddingRight:20}}/>Pisys Corporation.</p>
                <p className="companysub">Soluciones Informáticas, Infraestructura y Equipo. </p>
                
                <Typed 
                    className="typed-text"
                    strings={["Sistemas WEB a la medida", "Landing Pages", "Instalación de Redes y VPN's","Cableado Estructurado", "CCTV", "Venta de Equipo"]}
                    typeSpeed={50}
                    backSpeed={40}
                    loop
                />
                <Link smooth={true} to="services" href="#" className="btn-main-offer">Leer mas</Link>
            </div>
        </div>
    )
}

export default Header;