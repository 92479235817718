import React from "react";
import {Link} from "react-scroll";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    RedditShareButton,
    RedditIcon,
    LinkedinShareButton,
    LinkedinIcon
} from "react-share";

const Footer = () => {
    return(
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="d-flex">
                             <p>Col. Del Valle, CDMX, México</p>
                        </div>
                        <div className="d-flex">
                            <p style={{color:'greenyellow',fontSize:'1rem', paddingTop:10}}>+1(52)5511295030</p>
                        </div>
                        <div className="d-flex">
                             <p>avela@pisys.mx</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-6">
                        <div className="row">
                            <div className="col">
                                <Link smooth={true} to="home" className="footer-nav">Inicio</Link>
                                <br/>
                                <Link smooth={true} to="about" className="footer-nav">Acerca de</Link>
                                <br/>
                                <Link smooth={true} to="services" className="footer-nav">Services</Link>
                            </div>
                            <div className="col">
                                <Link smooth={true} to="software" className="footer-nav">Software</Link>
                                <br/>
                                <Link smooth={true} to="hardware" className="footer-nav">Hardware</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
                        <div className="d-flex justify-content-center">
                            <FacebookShareButton
                                url={"https://www.youtube.com/8020coding"}
                                quote={"FullStack Developer"}
                                hashtag="#javascript"
                            >
                                <FacebookIcon className="mx-3" size={36} />
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={"https://www.youtube.com/8020coding"}
                                quote={"FullStack Developer"}
                                hashtag="#javascript"
                            >
                                <TwitterIcon className="mx-3" size={36} />
                            </TwitterShareButton>
                            <RedditShareButton
                                url={"https://www.youtube.com/8020coding"}
                                quote={"FullStack Developer"}
                                hashtag="#javascript"
                            >
                                <RedditIcon className="mx-3" size={36} />
                            </RedditShareButton>
                            <LinkedinShareButton
                                url={"https://www.youtube.com/8020coding"}
                                quote={"FullStack Developer"}
                                hashtag="#javascript"
                            >
                                <LinkedinIcon className="mx-3" size={36} />
                            </LinkedinShareButton>
                        </div>
                        <p className="pt-3 text-center">
                           Copyright&copy;
                           {new Date().getFullYear()}&nbsp; Pisys | All Rights Reserved 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;